import { SubscriptionState } from '@src/interfaces/auth'
import { EmployeeInterface } from '@src/interfaces/employees'

export enum AuthActionTypes {
  SET_API_TOKEN = '@@auth/SET_API_TOKEN',
  TEST_LOGIN = '@@auth/TEST_LOGIN',
  SET_LOGIN_URL = '@@auth/SET_LOGIN_URL',
  GET_LOGIN_URL = '@@auth/GET_LOGIN_URL',
  VERIFY_API_TOKEN = '@@auth/VERIFY_API_TOKEN',
  LOG_OUT = '@@auth/LOG_OUT',
  LOG_IN = '@@auth/LOG_IN',
  SET_AUTHENTICATED = '@@auth/SET_AUTHENTICATED',
  SET_PERMISSIONS = '@@auth/SET_PERMISSIONS',
  SET_FEATURE_FLAGS = '@@auth/SET_FEATURE_FLAGS',
  SET_USER = '@@auth/SET_USER',
  SET_SUBSCRIPTION_STATE = '@@auth/SET_SUBSCRIPTION_STATE',
}

export enum LocalStorageKeys {
  STARRED_SEARCH_ITEMS = 'persist--starred_search_items',
  TABLE_ROW_HEIGHT = 'persist--table-row-height',
  TABLE_SETTINGS_KEY_TEMPLATE = 'persist--table-settings-{}',
  NAVIGATION_TABS_SETTINGS = 'persist--navigation-tabs-settings-{}',
  PINNED_APPS = 'persist--pinned-apps',
  PINNED_TABS = 'persist--pinned-tabs',
  HIDE_COMP_BANNER = 'persist--hide-comp-banner',
  ENGAGEMENT_WELCOME_PAGES_SEEN = 'persist--welcome_pages_seen',
  THEME = 'persist--theme',
  LOGIN_THEME = 'login-theme',
  ORIGIN_DEV_SUBDOMAINS = 'origin-dev-subdomains',
  GOOGLE_PERMISSIONS_ERROR = 'google-permissions-error',
  ALLOW_CONTINUE_ONBOARDING = 'allow-continue-onboarding',
  ACTIVE_WORKSPACE = 'persist--active-workspace',
  WORKSPACES = 'persist--workspaces',
  ENABLE_DEV_WORKSPACES = 'persist--enable-dev-workspaces',
}

export enum PermissionTypes {
  AccessCommentsEmployees = 'access_comments_employees',
  AccessDocumentConfidentialityField = 'access_document_confidentiality_field',
  AccessResignationComments = 'access_comments_resignation',
  AddAssociation = 'add_association',
  AddAudit = 'add_audit',
  AddAuthtoken = 'add_authtoken',
  AddBusinessdebt = 'add_businessdebt',
  AddCardfield = 'add_cardfield',
  AddCode = 'add_code',
  AddCommunicationGroup = 'add_communicationgroup',
  AddCompanyGoal = 'add_companygoal',
  AddCompanyKpi = 'add_company_kpi',
  AddConfluence = 'add_confluence',
  AddContenttype = 'add_contenttype',
  AddCustomField = 'add_customfield',
  AddDepartment = 'add_department',
  ManageDepartments = 'departments_manager_permissions',
  ManageTeams = 'department_owner_permissions',
  ManageRoles = 'roles_manager_permissions',
  AddDepartmentKpi = 'add_department_kpi',
  CanAllowInternalCurrencyChangeEmployeeContract = 'can_allow_internal_currency_change_employeecontract',
  BulkEditEmployees = 'add_employeebulkedit',
  BulkEditContracts = 'add_contractbulkedit',
  BulkUploadContracts = 'add_contractbulkupload',
  AddContractType = 'add_contracttype',
  ViewContractType = 'view_contracttype',
  DeleteContractType = 'delete_contracttype',
  CanManageBanners = 'can_manage_banners',
  ViewAttendanceTrackingPreferences = 'view_attendancetrackingsettingspreferences',
  ChangeAttendanceTrackingPreferences = 'change_attendancetrackingsettingspreferences',
  AddAttendanceTrackingPreferences = 'add_attendancetrackingsettingspreferences',
  DeleteAttendanceTrackingPreferences = 'delete_attendancetrackingsettingspreferences',
  ViewCompanyCompensationReview = 'view_companycompensationreview',
  ViewCompensationReviewSettings = 'view_compensationreviewsettings',
  CanManageCompanyLogo = 'can_manage_company_logo',
  ViewCompanySettings = 'view_companysettings',
  ChangeCompanySettings = 'change_companysettings',
  ChangeCompanyPreferences = 'change_companypreferences',
  ViewSlackIntegration = 'view_slackintegrationpreferences',
  ChangeSlackIntegration = 'change_slackintegrationbots',
  ViewJiraIntegration = 'view_jiraintegrationpreferences',
  ChangeJiraIntegration = 'change_jiraintegrationpreferences',
  ViewDocuSignIntegration = 'view_docusignintegrationpreferences',
  ChangeDocuSignIntegration = 'change_docusignintegrationpreferences',
  ViewGoogleCalendarIntegration = 'view_googlecalendarintegrationpreferences',
  ChangeGoogleCalendarIntegration = 'change_googlecalendarintegrationpreferences',
  ViewSecuritySettings = 'view_securitysettings',
  ChangeSecuritySettings = 'change_securitysettings',
  ViewContractsPreferences = 'view_contractspreferences',
  ChangeContractsPreferences = 'change_contractspreferences',
  ViewEmployeePreferences = 'view_employeepreferences',
  ChangeEmployeePreferences = 'change_employeepreferences',
  ViewHelpCenterPreferences = 'view_helpcenterpreferences',
  ChangeHelpCenterPreferences = 'change_helpcenterpreferences',
  ViewLifecycleSettings = 'view_lifecyclesettings',
  ChangeLifecycleSettings = 'change_lifecyclesettings',
  ViewCommunicationSettings = 'view_communicationsettings',
  ChangeCommunicationSettings = 'change_communicationsettings',
  ViewTimeOffPreferences = 'view_timeoffpreferences',
  ChangeTimeOffPreferences = 'change_timeoffpreferences',
  ViewDocumentsPreferences = 'view_documentpreferences',
  ChangeDocumentsPreferences = 'change_documentpreferences',
  ViewRequisitionPreferences = 'view_requisitionpreferences',
  ChangeRequisitionPreferences = 'change_requisitionpreferences',
  ViewCandidatePreferences = 'view_candidatepreferences',
  ChangeCandidatePreferences = 'change_candidatepreferences',
  ChangeHiringProcessPreferences = 'change_hiringprocesspreferences',
  ViewHiringProcessPreferences = 'view_hiringprocesspreferences',
  ViewJobPostingPreferences = 'view_jobpostingpreferences',
  ChangeJobPostingPreferences = 'change_jobpostingpreferences',
  ViewApplicationFormPreferences = 'view_applicationformpreferences',
  AddApplicationFormPreferences = 'add_applicationformpreferences',
  ChangeApplicationFormPreferences = 'change_applicationformpreferences',
  DeleteApplicationFormPreferences = 'delete_applicationformpreferences',
  ViewCareerPagePreferences = 'view_careerpagepreferences',
  ChangeCareerPagePreferences = 'change_careerpagepreferences',
  AddCareerPagePreferences = 'add_careerpagepreferences',
  ViewOfferFormPreferences = 'view_offerformpreferences',
  ChangeOfferFormPreferences = 'change_offerformpreferences',
  ViewOrganisationPreferences = 'view_organisationpreferences',
  ChangeOrganisationPreferences = 'change_organisationpreferences',
  ViewAccessManagementPreferences = 'view_accessmanagementsettings',
  ChangeAccessManagementPreferences = 'change_accessmanagementsettings',
  ViewLocationPreferences = 'view_locationpreferences',
  ChangeLocationPreferences = 'change_locationpreferences',
  ViewSkillsPreferences = 'view_skillspreferences',
  ChangeSkillsPreferences = 'change_skillspreferences',
  ViewTodoPreferences = 'view_todosettings',
  ChangeTodoPreferences = 'change_todosettings',
  ViewPerformancePreferences = 'view_performancepreferences',
  ChangePerformancePreferences = 'change_performancepreferences',
  ViewRoadmapPreferences = 'view_roadmapspreferences',
  ChangeRoadmapPreferences = 'change_roadmapspreferences',
  ChangeCompanyCompensationReview = 'change_companycompensationreview',
  AddCompanyCompensationReview = 'add_companycompensationreview',
  BypassEmployeeCompensationReviewRecommendations = 'bypass_employee_compensation_review_recommendations',
  ChangeCompensationReviews = 'change_compensation_reviews',
  ApproveDepartmentCompensationReview = 'approve_departmentcompensationreview',
  UnapproveDepartmentCompensationReview = 'unapprove_departmentcompensationreview',
  ChangeDepartmentCompensationReview = 'change_departmentcompensationreview',
  ViewEmployeeCompensationReviewBulkUploadSession = 'view_employeecompensationreviewbulkuploadsession',
  AddEmployeeCompensationReviewBulkUploadSession = 'add_employeecompensationreviewbulkuploadsession',
  ViewEmployeeBonusBulkUploadSession = 'view_employeebonusbulkuploadsession',
  AddEmployeeBonus = 'add_employeebonusv2',
  EditEmployeeBonus = 'edit_employeebonusv2',
  DeleteEmployeeBonus = 'delete_employeebonusv2',
  AddEmployeeBonusBulkUploadSession = 'add_employeebonusbulkuploadsession',
  AddDocumenttemplaterules = 'add_documenttemplaterules',
  AddDocumenttemplates = 'add_documenttemplates',
  AddDocumentCategories = 'add_documentcategories',
  ViewDocumentCategories = 'view_documentcategories',
  ViewEmployeeDocuments = 'view_employeedocuments',
  DeleteDocumentCategories = 'delete_documentcategories',
  ApproveUploadedDocuments = 'approve_uploadeddocuments',
  AddCompanyValue = 'add_companyvalue',
  AddDynamicGroups = 'add_dynamicgroups',
  AddEmployeeContract = 'add_employeecontract',
  AddEmployeeContractComments = 'add_employeecontract_comments',
  AddEmployeeManualLabelAssignments = 'add_employeemanuallabelassignments',
  AddEmployees = 'add_employees',
  AddEngagementquestion = 'add_engagementquestion',
  AddEntity = 'add_entity',
  AddFunction = 'add_function',
  AddFunctionKpi = 'add_function_kpi',
  AddGroup = 'add_group',
  AddServiceAccount = 'add_serviceaccount',
  AddHiringstage = 'add_hiringstage',
  AddHistory = 'add_history',
  AddHometaskinterviewers = 'add_hometaskinterviewers',
  AddHometasks = 'add_hometasks',
  AddInterviewRound = 'add_interviewround',
  AddInterviewScorecardTemplate = 'add_interviewscorecardtemplate',
  AddJobDescriptions = 'add_jobposting',
  ChangeJobDescriptions = 'change_jobposting',
  AddKpi = 'add_kpi',
  ViewEmployeesprobation = 'view_employeesprobation',
  ProbationCommitteeHRPermissions = 'probation_committee_hr_permissions',
  DeleteEmployeePerformanceCycle = 'delete_employeeperformancecycle',
  AddLanguages = 'add_languages',
  AddLocations = 'add_locations',
  AddLookerFolderUserAccessRequest = 'add_looker_folder_user_access_request',
  AddLookerModelUserAccessRequest = 'add_looker_model_user_access_request',
  AddMetabaseDatabaseUserAccessRequest = 'add_metabase_database_user_access_request',
  AddNonce = 'add_nonce',
  AddPartial = 'add_partial',
  AddPerformanceResultRecommendation = 'add_performanceresultrecommendation',
  AddPipCycle = 'add_employeeperformancecycle',
  AddPerformanceDecision = 'add_performancedecision',
  AddProbationRecommendation = 'add_probationdecision',
  AddResignation = 'add_resignation',
  AddRecruitmentgroups = 'add_recruitmentgroups',
  AddRequisitions = 'add_requisitions',
  CanEnableSandboxMode = 'can_enable_sandbox_mode',
  AddReview = 'submit_scorecards_employees',
  AddReviewCycles = 'add_reviewscycles',
  DeleteReviewCycles = 'delete_reviewscycles',
  AddRoadmap = 'add_roadmap',
  AddRoleKpi = 'add_role_kpi',
  AddRoles = 'add_roles',
  AddSalarySacrifice = 'add_salary_sacrifice',
  AddScorecards = 'add_scorecards',
  AddScorecardsection = 'add_scorecardsection',
  AddSeniority = 'add_seniority',
  AddSession = 'add_session',
  AddSkillset = 'add_skillset',
  AddSpecialisation = 'add_specialisation',
  AddTeamAccessRequests = 'add_team_access_requests',
  AddTeamKpi = 'add_team_kpi',
  AddTeams = 'add_teams',
  ChangeEmployeelinkedaccount = 'change_employeelinkedaccount',
  AddEmployeelinkedaccount = 'add_employeelinkedaccount',
  ViewEmployeelinkedaccount = 'view_employeelinkedaccount',
  AddToken = 'add_token',
  AddUploadeddocuments = 'add_uploadeddocuments',
  AddUser = 'add_user',
  AddUsersocialauth = 'add_usersocialauth',
  All = 'all',
  AnonymizeCandidate = 'anonymise_candidate',
  ApproveEmployeeContract = 'approve_employeecontract',
  ApproveLookerfolderuseraccessrequests = 'approve_lookerfolderuseraccessrequests',
  ApproveLookermodeluseraccessrequests = 'approve_lookermodeluseraccessrequests',
  ApprovePendingEmployees = 'approve_pending_employees',
  ApproveRequisitions = 'approve_requisitions',
  ApproveRoles = 'approve_roles',
  ArchiveRoles = 'archive_roles',
  ApproveScorecardTemplate = 'approve_hiringscorecardtemplate',
  ApproveSkillset = 'approve_skillset',
  ApproveSpecialisation = 'approve_specialisation',
  ArchiveSpecialisation = 'archive_specialisation',
  ArchiveEngagementDriver = 'archive_engagementdriver',
  ArchiveEngagementQuestion = 'archive_engagementquestion',
  ArchiveEngagementSurvey = 'archive_engagementsurvey',
  CanAddKPIsTeam = 'can_add_kpis_teams',
  CanAddKPIsDepartment = 'can_add_kpis_department',
  CanViewSkillTalent = 'can_view_skill_talent',
  CanDelegateCalibrators = 'can_delegate_calibrators',
  CancelAccessrequests = 'cancel_accessrequests',
  CancelEmployeeContract = 'cancel_employeecontract',
  CancelEngagementSurvey = 'cancel_engagementsurvey',
  CancelLookerfolderuseraccessrequests = 'cancel_lookerfolderuseraccessrequests',
  CancelLookermodeluseraccessrequests = 'cancel_lookermodeluseraccessrequests',
  CancelPendingScorecard = 'cancel_pending_scorecard',
  CancelTeamaccessrequests = 'cancel_teamaccessrequests',
  CancelEmployeeTermination = 'cancel_employeetermination',
  CanInitiateTermination = 'initiate_termination_employees',
  CanTerminateEmployees = 'terminate_employees',
  CanInviteEmployees = 'can_invite_employees',
  CanViewPerformanceReviewSpecialisation = 'can_view_performance_reviews_specialisation',
  CanCreateRoadmaps = 'can_create_roadmaps',
  ChangeAssociation = 'change_association',
  ChangeAudit = 'change_audit',
  ChangeAuthtoken = 'change_authtoken',
  ChangeBusinessdebt = 'change_businessdebt',
  ChangeCardfield = 'change_cardfield',
  ChangeCode = 'change_code',
  ChangeCompanyGoal = 'change_companygoal',
  ChangeConfluence = 'change_confluence',
  ChangeContenttype = 'change_contenttype',
  ChangeDepartment = 'change_department',
  ChangeDocumenttemplaterules = 'change_documenttemplaterules',
  ChangeDocumenttemplates = 'change_documenttemplates',
  ChangeDynamicGroups = 'change_dynamicgroups',
  ChangeEmployeechangelogitem = 'change_employeechangelogitem',
  ChangeEmployeeContract = 'change_employeecontract',
  ChangeEmployeeContractBase = 'can_change_employee_contract_base',
  ChangeEmployeeContractElevated = 'can_change_employee_contract_elevated',
  ChangeEmployeeContractComments = 'change_employeecontract_comments',
  ChangeEmployees = 'change_employees',
  ChangeEmployeeStatus = 'change_employee_status',
  ChangeEngagement = 'change_engagement',
  ChangeEngagementDriver = 'change_engagementdriver',
  ChangeEngagementQuestion = 'change_engagementquestion',
  ChangeEngagementSurvey = 'change_engagementsurvey',
  ChangeEntity = 'change_entity',
  ChangeFunction = 'change_function',
  CanViewCalibrationTable = 'can_view_calibration_table',
  ChangeGradeSuggestion = 'change_grade_suggestion',
  ChangeGroup = 'change_group',
  ChangeHistory = 'change_history',
  ChangeHometaskinterviewers = 'change_hometaskinterviewers',
  ChangeHometasks = 'change_hometasks',
  ChangeInterviewFeedback = 'change_interviewfeedback',
  ChangeInterviewRound = 'change_interviewround',
  ChangeJobdescriptions = 'change_jobdescriptions',
  ChangeKpi = 'change_kpi',
  ChangeKPIchangelogitem = 'change_kpichangelogitem',
  ChangeKpiWeight = 'change_kpi_weight',
  CanRefreshKpis = 'can_refresh_kpis',
  ChangeLanguages = 'change_languages',
  ChangeLocations = 'change_locations',
  ChangeNonce = 'change_nonce',
  ChangePartial = 'change_partial',
  ChangePerformanceResultRecommendation = 'change_performanceresultrecommendation',
  ChangePermission = 'change_permission',
  ChangePerformanceDecision = 'change_performancedecision',
  ChangeProbationRecommendation = 'change_probationdecision',
  ChangeRecruitmentgroups = 'change_recruitmentgroups',
  ChangeReferralBonus = 'change_referralbonus',
  ChangeRequisitions = 'change_requisitions',
  ChangeResignation = 'change_resignation',
  ChangeResignationApproval = 'change_approval_resignation',
  ChangeReviewCycles = 'change_reviewscycles',
  ChangeRoadmap = 'change_roadmap',
  ChangeRoles = 'change_roles',
  ChangeScorecards = 'change_scorecards',
  ChangeScorecardsection = 'change_scorecardsection',
  ChangeScorecardTemplate = 'change_hiringscorecardtemplate',
  ChangeSeniority = 'change_seniority',
  ChangeSession = 'change_session',
  ChangeSkillset = 'change_skillset',
  ChangeSpecialisation = 'change_specialisation',
  ChangeTeams = 'change_teams',
  ChangeToken = 'change_token',
  ChangeUser = 'change_user',
  ChangeUsersocialauth = 'change_usersocialauth',
  ChangeBenefitsTemplate = 'change_benefitstemplate',
  ViewBenefitsTemplate = 'view_benefitstemplate',
  ViewBenefits = 'view_benefits',
  CreateAccessRequests = 'create_access_requests',
  CreateChangeRequestSetWithoutApproval = 'create_change_request_set_without_approval',
  DdPermission = 'add_permission',
  DeleteAssociation = 'delete_association',
  DeleteAudit = 'delete_audit',
  DeleteAuthtoken = 'delete_authtoken',
  DeleteBusinessdebt = 'delete_businessdebt',
  DeleteCardfield = 'delete_cardfield',
  DeleteCode = 'delete_code',
  DeleteConfluence = 'delete_confluence',
  DeleteContenttype = 'delete_contenttype',
  DeleteCustomField = 'delete_customfield',
  DeleteDepartment = 'delete_department',
  DeleteDocumenttemplaterules = 'delete_documenttemplaterules',
  DeleteDocumenttemplates = 'delete_documenttemplates',
  DeleteDynamicGroups = 'delete_dynamicgroups',
  DeleteEmployees = 'delete_employees',
  DeleteEntity = 'delete_entity',
  DeleteFunction = 'delete_function',
  DeleteGroup = 'delete_group',
  DeleteHiringstage = 'delete_hiringstage',
  DeleteHistory = 'delete_history',
  DeleteHometaskinterviewers = 'delete_hometaskinterviewers',
  DeleteHometasks = 'delete_hometasks',
  DeleteJobdescriptions = 'delete_jobdescriptions',
  DeleteKpi = 'delete_kpi',
  DeleteLanguages = 'delete_languages',
  DeleteLocations = 'delete_locations',
  DeleteLookerfolderuseraccessrequests = 'delete_lookerfolderuseraccessrequests',
  DeleteLookermodeluseraccessrequests = 'delete_lookermodeluseraccessrequests',
  DeleteNonce = 'delete_nonce',
  DeletePartial = 'delete_partial',
  DeletePermission = 'delete_permission',
  DeleteRecruitmentgroups = 'delete_recruitmentgroups',
  DeleteRequisitions = 'delete_requisitions',
  DeleteResignation = 'delete_resignation',
  DeleteRoadmap = 'delete_roadmap',
  DeleteRoles = 'delete_roles',
  DeleteScorecards = 'delete_scorecards',
  DeleteScorecardsection = 'delete_scorecardsection',
  DeleteSeniority = 'delete_seniority',
  DeleteSession = 'delete_session',
  DeleteSkillset = 'delete_skillset',
  DeleteSpecialisation = 'delete_specialisation',
  DeleteTeams = 'delete_teams',
  DeleteToken = 'delete_token',
  DeleteUser = 'delete_user',
  DeleteUsersocialauth = 'delete_usersocialauth',
  DownloadDocusignenveloperecipients = 'download_docusignenveloperecipients',
  DownloadSentenveloperecipientsview = 'download_sentenveloperecipientsview',
  EngagementManagerPermissions = 'engagement_manager_permissions',
  EditOrigin = 'edit_origin',
  PreApproveRequisitions = 'preapprove_requisitions',
  PreApproveRoles = 'preapprove_roles',
  PreApproveSkillset = 'preapprove_skillset',
  PreApproveSpecialisation = 'preapprove_specialisation',
  Publish = 'publish_requisitions',
  RecApproveSpecialisation = 'recapprove_specialisation',
  RejectLookerfolderuseraccessrequests = 'reject_lookerfolderuseraccessrequests',
  RejectLookermodeluseraccessrequests = 'reject_lookermodeluseraccessrequests',
  RejectEmployeeContract = 'reject_employeecontract',
  ReorderRequisitions = 'reorder_requisitions_specialisation',
  RequestReview = 'request_review_employees',
  RevokeAccessrequests = 'revoke_accessrequests',
  RevokeLookerfolderuseraccessrequests = 'revoke_lookerfolderuseraccessrequests',
  RevokeLookermodeluseraccessrequests = 'revoke_lookermodeluseraccessrequests',
  RevokeTeamAccessRequests = 'revoke_teamaccessrequests',
  SeeAddFeedbackButton = 'see_add_feedback_button',
  SeeScheduleInterviewButton = 'see_schedule_interview_button',
  SeeSchedulingInterviewInformation = 'see_scheduling_interview_information',
  SetAllowance = 'set_extra_allowance',
  SetGooalsEmployees = 'set_goals_employees',
  ChangePerformanceGoal = 'change_performancegoal',
  SignSentenveloperecipientsview = 'sign_sentenveloperecipientsview',
  UploadDocuments = 'upload_documents',
  ViewAccessRequests = 'view_access_requests',
  ViewAssociation = 'view_association',
  ViewAudit = 'view_audit',
  ViewAuthtoken = 'view_authtoken',
  ViewBonusEmployee = 'view_bonus_employee',
  ViewBusinessdebt = 'view_businessdebt',
  ViewCardfield = 'view_cardfield',
  ViewCode = 'view_code',
  ViewComplaintsComments = 'view_cx_complaints_comments',
  ViewCompanyValue = 'view_companyvalue',
  ViewConfluence = 'view_confluence',
  ViewContenttype = 'view_contenttype',
  ViewContractsTab = 'view_contracts_tab',
  ViewCultureEmployee = 'view_culture_employee',
  ViewCustomField = 'view_customfield',
  ViewDepartment = 'view_department',
  ViewDepartmentBudget = 'view_departmentbudget',
  ViewDetractorsComments = 'view_cx_csat_detractors_comments',
  ViewDocuments = 'view_documents',
  ViewDocumenttemplaterules = 'view_documenttemplaterules',
  ViewDocumenttemplates = 'view_documenttemplates',
  ViewDocumentBulkRequest = 'view_documentbulkrequest',
  AddDocumentBulkRequest = 'add_documentbulkrequest',
  ViewDynamicGroups = 'view_dynamicgroups',
  ViewEmployeechangelogitem = 'view_employeechangelogitem',
  ViewEmployeecontract = 'view_employeecontract',
  ViewEmployeediversity = 'view_employeediversity',
  ViewEmployeeOwnershipTab = 'view_employee_ownership_tab',
  ViewEmployeepayrolldetails = 'view_employeepayrolldetails',
  ViewEmployeerighttowork = 'view_employeerighttowork',
  ViewEmployeeAudits = 'view_employee_audits',
  ViewEmployeeContractComments = 'view_employeecontract_comments',
  ViewEmployees = 'view_employees',
  ViewEmployeespersonalinfo = 'view_employeespersonalinfo',
  ViewEmployeetermination = 'view_employeetermination',
  HasOffboardingV2Enabled = 'has_offboarding_2_0_enabled',
  ViewOffboardingV2 = 'view_offboarding_2_0',
  ViewEngagement = 'view_engagement',
  ViewEngagementTabs = 'view_engagement_tabs',
  ViewEngagementanswer = 'view_engagementanswer',
  ViewEngagementComments = 'view_engagement_comments',
  ViewEngagementquestion = 'view_engagementquestion',
  ViewEngagementSurveyRound = 'view_engagementsurveyround',
  ViewEntity = 'view_entity',
  ViewFunction = 'view_function',
  ViewGroup = 'view_group',
  ViewHiringstage = 'view_hiringstage',
  ViewHometaskinterviewers = 'view_hometaskinterviewers',
  ViewHometasks = 'view_hometasks',
  ViewInterviewFeedback = 'view_interviewfeedback',
  ViewInterviewScorecardTemplate = 'view_interviewscorecardtemplate',
  ViewJobdescriptions = 'view_jobposting',
  ViewKpi = 'view_kpi',
  ViewKpiTemplate = 'view_kpitemplate',
  ViewKri = 'view_kris',
  ViewLanguages = 'view_languages',
  ViewCandidate = 'view_candidate',
  ViewLocations = 'view_locations',
  ViewNonce = 'view_nonce',
  ViewPartial = 'view_partial',
  ViewEmployeeHROnboardingProcess = 'view_employeehronboardingprocess',
  ViewResignations = 'view_resignation',
  ApproveOnboarding = 'approve_onboarding',
  ViewPermission = 'view_permission',
  ViewRecruitmentgroups = 'view_recruitmentgroups',
  ViewReferralBonus = 'view_referralbonus',
  ViewReportsPerformanceEmployees = 'view_reports_performance_employees',
  ViewRequisitions = 'view_requisitions',
  ViewRequisitionsCandidates = 'view_requisitions_candidates',
  ViewRequisitionHires = 'view_hires_requisitions',
  MoveRequisitionHires = 'move_hires_requisitions',
  ViewResultsPendingKpi = 'view_results_pending_kpi',
  ViewReviewCycles = 'view_reviewscycles',
  ViewReviewsWhitelist = 'view_reviewsemployeeswhitelist',
  ViewProbationEmployees = 'view_employeesprobation',
  ViewRewardsTab = 'view_rewards_tab',
  ViewRoadmap = 'view_roadmap',
  CanAddEmployeeRoadmap = 'add_employee_roadmap',
  CanAddTeamRoadmap = 'add_team_roadmap',
  CanAddDepartmentRoadmap = 'add_department_roadmap',
  CanAddCompanyRoadmap = 'add_company_roadmap',
  ViewRoles = 'view_roles',
  ViewSalarySacrifice = 'view_salary_sacrifice',
  ViewScorecards = 'view_scorecards',
  ViewScorecardsection = 'view_scorecardsection',
  ViewScorecardTemplate = 'view_hiringscorecardtemplate',
  ViewScreeningChecks = 'view_screeningchecks',
  ViewAllScreeningChecks = 'view_allscreeningchecks',
  ViewSeniority = 'view_seniority',
  ViewSession = 'view_session',
  ViewSkillset = 'view_skillset',
  ViewSpecialisation = 'view_specialisation',
  ViewSpecialisationCandidates = 'view_specialisation_candidates',
  ViewSummaryMatrix = 'view_summary_matrix',
  ViewSupportLoadComments = 'view_cx_support_load_comments',
  ViewTalentSpecialisation = 'view_talent_specialisation',
  ViewTeamAccessRequests = 'view_team_access_requests',
  ViewTeamBudget = 'view_teambudget',
  ViewTeams = 'view_teams',
  ViewTemplatedNotifications = 'view_templatednotifications',
  ViewToken = 'view_token',
  ViewTrustPilotComments = 'view_cx_trustpilot_comments',
  ViewUser = 'view_user',
  ViewUserfeedback = 'view_userfeedback',
  GetActivationUrl = 'get_activation_url',
  CanCreateMagicLinkManually = 'can_create_magic_link_manually',
  ViewUsersocialauth = 'view_usersocialauth',
  SetCalibrationFactor = 'set_kpi_calibration_factor',
  ChangeEmployeeUploadingSession = 'change_employeesuploadingsession',
  AddEmployeeUploadingSession = 'add_employeesuploadingsession',
  AddEmployeeUpload = 'add_employeeupload',
  HRManagerPermissions = 'hr_manager_permissions',
  DocumentsManagerPermissions = 'documents_manager_permissions',
  PromotionsManagerPermissions = 'promotions_manager_permissions',
  ChangeTalentRank = 'change_talent_ranks',
  ViewCompanyPerformanceTalentTab = 'view_company_performance_talent_tab',
  ViewEmployeeExpenses = 'can_view_talent_expenses',
  ViewEmployeePerformance = 'can_view_talent_performance',
  TransferEmployeesTeams = 'can_transfer_employees_teams',
  CanAccessKPIComments = 'access_comments_kpi',
  AddRequisitionForTeams = 'can_add_requisitions_teams',
  AddKPIBulkUploadSession = 'add_kpibulkuploadsession',
  ApproveAllRequisitions = 'approve_all_requisitions',
  ViewBenchmarks = 'view_benchmarks',
  AddBenchmarks = 'add_benchmarks',
  AddBenchmarksBulkUploadSession = 'add_benchmarksuploadingsession',
  BulkBenchmarkApprove = 'bulk_benchmark_approve',
  BulkBenchmarkArchive = 'bulk_benchmark_archive',
  BulkBenchmarkUnarchive = 'bulk_benchmark_unarchive',
  ViewBudgetPoolConfig = 'view_budgetpoolconfig',
  AddBudgetPoolConfig = 'add_budgetpoolconfig',
  AddBenefitsTemplate = 'add_benefitstemplate',
  ViewInbox = 'view_inbox',
  ViewPerformanceHistoryTab = 'view_performance_history_tab',
  ViewAllPerformanceTimelines = 'view_all_performance_timelines',
  AddAdjustments = 'add_adjustments',
  ViewResignation = 'view_resignation',
  ViewResignationApproval = 'view_resignationapproval',
  ViewTimeOffPolicies = 'view_time_off_policies',
  AddTimeOffPolicies = 'add_time_off_policies',
  AddPromotionNomination = 'add_promotion_nomination',
  PromotionsAnyTimeNomination = 'promotions_any_time_nomination',
  ViewTimeOffRegimes = 'view_time_off_regimes',
  AddTimeOffRegimes = 'add_time_off_regimes',
  ChangeTimeOffRegime = 'change_timeoffregime',
  DeleteTimeOffRegime = 'delete_timeoffregime',
  AddTimeOffPolicyCategory = 'add_timeoffpolicycategory',
  ViewTimeOffPolicyCategory = 'view_timeoffpolicycategory',
  DeleteTimeOffPolicyCategory = 'delete_timeoffpolicycategory',
  ViewTimeOff = 'view_time_off',
  ViewTimeOffBalances = 'view_time_off_balances',
  AddTimeOffBalanceAdjustments = 'add_time_off_balance_adjustments',
  ViewChangelogTimeoffRequest = 'view_changelog_timeoffrequest',
  AddPublicHolidays = 'add_public_holidays',
  ViewPublicHolidays = 'view_public_holidays',
  ArchivePublicHoliday = 'archive_publicholiday',
  ArchiveTimeOffPolicy = 'archive_timeoffpolicy',
  ChangeTimeOffPolicy = 'change_timeoffpolicy',
  ArchiveTimeOffPolicyAssignments = 'archive_timeoffpolicyassignment',
  UnassignTimeOffPolicyAssignments = 'unassign_timeoffpolicyassignment',
  DeleteTimeOffPolicyAssignments = 'delete_timeoffpolicyassignment',
  ViewTimeOffPolicyAssignments = 'view_time_off_policy_assignments',
  ViewTimeOffRequests = 'view_time_off_requests',
  AddTimeOffRequests = 'add_time_off_requests',
  ApproveTimeOffRequest = 'approve_timeoffrequest',
  RejectTimeOffRequest = 'reject_timeoffrequest',
  ChangeTimeOffRequest = 'change_timeoffrequest',
  CancelTimeOffRequest = 'cancel_timeoffrequest',
  ViewOverlappingTimeOffRequests = 'view_overlapping_requests_timeoffrequest',
  AddTimeOffAbsences = 'add_time_off_absences',
  RequestTimeOff = 'request_time_off',
  AddTimeOffPolicyAssignments = 'add_time_off_policy_assignments',
  ViewTimeOffRequestsOfReports = 'view_time_off_requests_of_reports',
  ViewTimeOffRequestPolicy = 'view_time_off_request_policy',
  AddTimeOffRequestBulkUpload = 'add_timeoffrequestbulkupload',
  ViewEmployeeScheduleSettings = 'view_employeeschedulesettings',
  AddEmployeeScheduleSettings = 'add_employeeschedulesettings',
  ChangeEmployeeScheduleSettings = 'change_employeeschedulesettings',
  AddEmployeeShift = 'add_employeeshift',
  ChangeEmployeeShift = 'change_employeeshift',
  ViewEmployeeShift = 'view_employeeshift',
  DeleteEmployeeShift = 'delete_employeeshift',
  ViewCommentsEmployeeShift = 'view_comments_employeeshift',
  AddCommentsEmployeeShift = 'add_comments_employeeshift',
  ViewAttendanceTab = 'view_attendance_tab',
  ViewShiftsSummary = 'view_shifts_summary',
  ViewDirectReportsSchedule = 'view_direct_reports_schedule',
  ViewSchedulingPolicy = 'view_schedulingpolicy',
  ViewShiftsBreakdown = 'view_shifts_breakdown',
  AddSchedulingPolicy = 'add_schedulingpolicy',
  AddRecruitmentCandidate = 'add_candidate',
  RecruitmentPermissions = 'recruitment_permissions',
  AddSpecialisationJobPosting = 'add_specialisation_job_posting',
  ViewApplicationForm = 'view_applicationform',
  ViewMeeting = 'view_meeting',
  ViewEmailTemplate = 'view_emailtemplate',
  AddEmailTemplate = 'add_emailtemplate',
  ViewEmployeeEmailTemplate = 'view_employeeemailtemplate',
  AddEmployeeEmailTemplate = 'add_employeeemailtemplate',
  ApproveProbationCheckpoint = 'approve_probationcheckpoint',
  ViewPromotionNomination = 'view_promotionnomination',
  ViewCommunicationGroup = 'view_communicationgroup',
  DelegatePromotionStage = 'delegate_promotionstage',
  ResetPromotionStage = 'reset_promotionstage',
  DeletePromotionNomination = 'delete_promotionnomination',
  ReopenPromotionNomination = 'reopen_promotionnomination',
  ChangePromotionStage = 'change_promotionstage',
  SkipPromotionStage = 'skip_promotionstage',
  ViewPromotionStage = 'view_promotionstage',
  ViewOfferFormTemplate = 'view_offerformtemplate',
  AddOfferFormTemplate = 'add_offerformtemplate',
  CanAssignLeadershipPositionsDepartment = 'can_assign_leadership_position_department',
  ViewBackfillsRequisitions = 'view_backfills_requisitions',
  ViewCandidateExtraSensitive = 'view_candidate_extra_sensitive',
  ViewOfferForm = 'view_offerform',
  ViewHiringProcessSpecialisation = 'view_hiring_process_specialisation',
  ViewDataRetentionPeriod = 'view_retentionperiod',
  AddDataRetentionPeriod = 'add_retentionperiod',
  ChangeDataRetentionPeriod = 'change_retentionperiod',
  ChangeRegulatedFlag = 'change_is_regulated_flag_requisitions',
  ViewUserGroups = 'view_expiringusergroup',
  AddUserGroups = 'add_expiringusergroup',
  AddUserPermissions = 'add_objectpermissions',
  ViewPermissions = 'view_objectpermissions',
  ViewCandidateOffers = 'view_candidate_offers',
  ChangeSpecialisationSublevels = 'change_specialisationsenioritysublevel',
  ViewKPITemplate = 'view_kpitemplate',
  CanManageCulturalSkills = 'can_manage_cultural_skills',
  ApproveScreeningCheck = 'approve_screeningcheck',
  ChangeScreeningCheck = 'change_screeningcheck',
  ViewScreeningPreferences = 'view_screeningpreferences',
  ChangeScreeningPreferences = 'change_screeningpreferences',
  KPIAdministratorPermissions = 'kpi_administrator_permissions',
  CanEditFeedback = 'can_edit_feedback',
  ViewHiringPipeline = 'view_hiringpipeline',
  AddPaygroup = 'add_paygroup',
  ChangePaygroup = 'change_paygroup',
  DeletePaygroup = 'delete_paygroup',
  ViewPaygroup = 'view_paygroup',
  CanViewPipSettings = 'can_view_pip_settings',
  CanViewPipComments = 'can_view_pip_comments',
  ManagePipCycles = 'manage_pip_cycles',
  ManagePipCommitteeDecision = 'manage_pip_committee_decision',
  CanViewPerformanceHistoryTab = 'view_performance_history_tab',
  CanViewAllPerformanceStats = 'view_all_performance_stats',
  CanCreateLifecycleTicket = 'can_create_lifecycle_ticket',
  ViewPayrollPreferences = 'view_payrollpreferences',
  ChangePayrollPreferences = 'change_payrollpreferences',
  AddPayCycle = 'add_paycycle',
  AddKeyPersonType = 'add_keypersontype',
  ViewKeyPersonType = 'view_keypersontype',
  ChangeKeyPersonType = 'change_keypersontype',
  ViewKeyPersonPreferences = 'view_keypersonsettingspreferences',
  ChangeKeyPersonPreferences = 'change_keypersonsettingspreferences',
  ViewKeyPersonsResponsibilityCategories = 'view_keypersonresponsibilitiescategory',
  AddKeyPersonsResponsibilityCategories = 'add_keypersonresponsibilitiescategory',
  ChangeKeyPersonsResponsibilityCategories = 'change_keypersonresponsibilitiescategory',
  DeleteKeyPersonsResponsibilityCategories = 'delete_keypersonresponsibilitiescategory',
  ViewKeyRiskProfile = 'view_keyriskprofile',
  AddKeyRiskProfile = 'add_keyriskprofile',
  ChangeKeyRiskProfile = 'change_keyriskprofile',
  DeleteKeyRiskProfile = 'delete_keyriskprofile',
  TransferKeyRiskProfile = 'key_risk_profile_transfer_responsibility_permissions',
  ViewKeyRiskProfileSuccessionPlans = 'view_keyriskprofilesuccessionplans',
  ChangeKeyRiskProfileSuccessionPlans = 'change_keyriskprofilesuccessionplans',
  ApproveKeyRiskProfileSuccessionPlans = 'approve_keyriskprofilesuccessionplans',
  RejectKeyRiskProfileSuccessionPlans = 'reject_keyriskprofilesuccessionplans',
  DeleteBookingLinkDefinition = 'delete_bookinglinkdefinition',
  AddBookingLinkDefinition = 'add_bookinglinkdefinition',
  ViewBookingLinkDefinition = 'view_bookinglinkdefinition',
  ChangeBookingLink = 'change_bookinglink',
  AddBookingLink = 'add_bookinglink',
  DeleteBookingLink = 'delete_bookinglink',
  ViewBookingLink = 'view_bookinglink',
  ManageSubscriptionPlans = 'manage_subscription_plans',
  ViewFaq = 'view_faq',
  AddFaq = 'add_faq',
  ChangeFaq = 'change_faq',
  ViewFaqTopic = 'view_faqtopic',
  CanChangeSavedQuery = 'change_savedquery',
  ManageQueryDeployments = 'manage_query_deployments',
  AddFaqTopic = 'add_faqtopic',
  ChangeFaqTopic = 'change_faqtopic',
  ChangeConnection = 'change_connection',
  CloseAccount = 'close_account',
  AccessObjectPermissionsConnection = 'access_object_permissions_connection',
  AccessObjectPermissionsSavedQuery = 'access_object_permissions_savedquery',
  UseReportingApp = 'use_reporting',
  ViewInterviewRoundCRM = 'view_interview_round_crm',
  ViewApplicationautomationrule = 'view_applicationautomationrule',
  UnarchiveInterviewRound = 'unarchive_interviewround',
  ManageDashboards = 'manage_dashboards',
  HasEditablePositionDetails = 'has_editable_position_details',
  EnabledTotalCompensationApp = 'enabled_total_compensation_app',
  ChangeTotalCompensationPreferences = 'change_totalcompensationpreferences',
  ViewTotalCompensationPreferences = 'view_totalcompensationpreferences',
  CanApprove = 'can_approve',
  CanReject = 'can_reject',
  Change = 'change',
  Delete = 'delete',
  CanChangeEmployeeGoal = 'change_employee_goal',
  CanChangeTeamGoal = 'change_team_goal',
  CanChangeDepartmentGoal = 'change_department_goal',
  CanChangeCompanyGoal = 'change_company_goal',
  CanDeleteEmployeeGoal = 'delete_employee_goal',
  CanDeleteTeamGoal = 'delete_team_goal',
  CanDeleteDepartmentGoal = 'delete_department_goal',
  CanDeleteCompanyGoal = 'delete_company_goal',
  CanCreateGoal = 'can_create_goal',
  CanAddGoals = 'can_add_goals',
  CanManageGoals = 'can_manage_goals',
  CanAddEmployeeGoal = 'add_employee_goal',
  CanAddTeamGoal = 'add_team_goal',
  CanAddDepartmentGoal = 'add_department_goal',
  CanAddCompanyGoal = 'add_company_goal',
  CanBulkApproveGoals = 'can_bulk_approve_goals',
  CanApproveEmployeeGoalInBulk = 'can_approve_employee_goals_in_bulk',
  CanApproveTeamGoalsInBulk = 'can_approve_team_goals_in_bulk',
  CanApproveDepartmentGoalsInBulk = 'can_approve_department_goals_in_bulk',
  CanApproveCompanyGoalsInBulk = 'can_approve_company_goals_in_bulk',
  AddDocumentCollection = 'add_documentcollection',
  ChangeDocumentCollection = 'change_documentcollection',
  AddLinkedInApiPosting = 'add_linkedinapiposting',
  DeleteLinkedInApiPosting = 'delete_linkedinapiposting',
  ViewLinkedInApiPosting = 'view_linkedinapiposting',
  ChangeLinkedInApiPosting = 'change_linkedinapiposting',
  DepartmentOwnerGradeSuggestion = 'department_owner_grade_suggestion',
  FunctionOwnerGradeSuggestion = 'function_owner_grade_suggestion',
  ViewCandidateBulkEmailRequest = 'view_candidatebulkemailrequest',
  ViewCandidateBulkArchiveRequest = 'view_candidatebulkarchiverequest',
  ViewCandidateBulkEditRequest = 'view_candidatebulkeditrequest',
  ViewCandidateBulkSendOnlineTestRequest = 'view_candidatebulksendonlinetestrequest',
  ViewCandidateBulkMoveToStageRequest = 'view_candidatebulkmovetostagerequest',
  ViewCandidateBulkSnoozeRequest = 'view_candidatebulksnoozerequest',
  ViewCandidateBulkUnsnoozeRequest = 'view_candidatebulkunsnoozerequest',
  ChangeHiringProcess = 'change_hiringprocess',
  ViewHiringProcess = 'view_hiringprocess',
  AddHiringProcess = 'add_hiringprocess',
  DeleteHiringProcess = 'delete_hiringprocess',
  AddInterviewScheduling = 'add_interviewscheduling',
  ChangeCandidate = 'change_candidate',
}

export enum FeatureFlags {
  PIPRemoval = 'pip_removal',
  PromotionNomination = 'promotion_nomination',
  SkillTalent = 'skill_talent',
  OneToOnes = 'one_to_one_meeting_interface',
  TenantAccounts = 'tenant_accounts',
  ManualRoadmaps = 'manual_roadmaps',
  BaseInterviewRoundOnJobPosting = 'base_interview_round_on_job_posting',
  PerformanceHistory = 'performance_history',
  EmployeeAnonymizationEnabled = 'employee_anonymization_enabled',
  AutomaticScreenings = 'automatic_screenings',
  CommercialProduct = 'commercial_product',
  TenantSandboxMode = 'tenant_sandbox_mode',
  PipGoalsJiraIntegrationDisabled = 'pip_goals_jira_integration_disabled',
  PayrollEmployee = 'payroll_employee',
  ReportingApp = 'reporting',
  ReportingConnectionsManagement = 'reporting_connections_management',
  ReportingQueriesManagement = 'reporting_queries_management',
  QueryDeployments = 'query_deployments',
  SubscriptionPlans = 'subscription_plans',
  SubscriptionPlansDevtools = 'subscription_plans_devtools',
  SubscriptionPlansCustomPlans = 'subscription_plans_custom_plans',
  TicketsApplication = 'tickets_application',
  EmployeeProfileOldLayout = 'employee_profile_old_layout',
  RoadmapRefreshV2 = 'roadmaps_refresh_v2',
  ChatbotBackgroundTickets = 'chatbot_background_tickets',
  FaqTriageServiceDesk = 'faq_triage_service_desk',
  ToDoInCalibrationComments = 'todo_in_calibration_comments',
  NewScorecards = 'scorecards_ui_q4_2023',
  CanAddGoals = 'can_add_goals',
  CanAddKPIs = 'can_add_kpis',
  EnableInternalDashboards = 'enable_internal_dashboards',
  Allowed_Looker = 'allowed_looker',
  OnboardingFlowV2 = 'onboarding_flow_v2',
  LinkedinIntegration = 'linkedin_integration',
  CustomHiringProcesses = 'custom_hiring_processes',
  CalibrationRankingScore = 'calibration_ranking_score',
  EditableEmployeesTable = 'editable_employees_table',
  EmployeeOnboardingV2 = 'employee_onboarding_v2',
  OfficeSuiteProvider = 'office_suite_provider',
  EngagementSurveys = 'engagement_surveys',
  GoogleWorkspaceIntegration = 'google_workspace_integration',
  SlackWorkspaceIntegration = 'slack_workspace_integration',
  ApplyInterviewsAvailabilityLimitsToSlots = 'apply_interviews_availability_limits_to_slots',
  CalendarScheduling = 'calendar_scheduling',
}

export enum GlobalSettings {
  CompensationEnabled = 'compensation_enabled',
  BenefitsEnabled = 'benefits_enabled',
  EngagementEnabled = 'engagement_enabled',
  PromotionsEnabled = 'promotions_enabled',
  FeedbackEnabled = 'feedback_enabled',
  LinkedAccountsEnabled = 'linked_accounts_enabled',
  KeyPersonEnabled = 'key_person_enabled',
  TimeOffEnabled = 'timeoff_enabled',
  ScreeningEnabled = 'screening_enabled',
  DocumentsTemplatesEnabled = 'enable_document_generation_from_templates',
  RequisitionsEnabled = 'requisitions_enabled',
  CandidatesEnabled = 'candidates_enabled',
  JobPostingsEnabled = 'job_postings_enabled',
  CommercialProductDisabled = 'commercial_product_disabled',
  ApplicantsTrackingEnabled = 'applicants_tracking_enabled',
  ResignationsEnabled = 'resignations_enabled',
  PayrollEnabled = 'payroll_enabled',
  CareerPageEnabled = 'career_page_enabled',
  ReferralsEnabled = 'referrals_enabled',
  RecruitmentGroupsEnabled = 'recruitment_groups_enabled',
  CandidateSchedulingEnabled = 'candidates_scheduling_enabled',
  OffersTemplatesEnabled = 'offers_templates_enabled',
  TotalCompensationEnabled = 'total_compensation_enabled',
  ProbationEnabled = 'probation_enabled',
  PipEnabled = 'pip_enabled',
}

export enum PerformanceSettings {
  EnableProbation = 'enable_probation',
  EnablePip = 'enable_pip',
}

export enum EntityPermissions {
  Add = 'add',
  Approve = 'approve',
  ApproveAll = 'approve_all',
  ApprovePending = 'approve_pending',
  Archive = 'archive',
  Cancel = 'cancel',
  Change = 'change',
  Close = 'close',
  Delete = 'delete',
  Publish = 'publish',
  Reject = 'reject',
  Reopen = 'reopen',
  Enrol = 'enrol',
  OptOut = 'opt_out',
  AccessObjectPermissions = 'access_object_permissions',
  AccessComments = 'access_comments',
  ViewCultureTabDepartment = 'view_culture_department',
  ViewCultureTabTeam = 'view_culture_teams',
  ViewCultureTabFunctions = 'view_culture_functions',
  ViewCultureTabRoles = 'view_culture_roles',
  ViewCultureTabSpecialisations = 'view_culture_specialisations',
  ViewKri = 'view_kris',
  ViewNPS = 'view_nps_tab',
  ViewSpentTab = 'view_spent_tab',
  CanEditAllFieldsInSection = 'can_edit_all_fields_in_section',
  CanEditFieldsInSection = 'can_edit_fields_in_section',
  CanRequestChangesInSection = 'can_request_changes_in_section',
  ArchivePublicHoliday = 'archive_publicholiday',
  ViewCompensationReviews = 'view_compensation_reviews',
  ViewOverlappingRequests = 'view_overlapping_requests',
  ApproveSchedule = 'approve_schedule',
  ViewComments = 'view_comments',
  AddComments = 'add_comments',
}

export interface AuthState {
  readonly authenticated: boolean
  readonly permissions: PermissionTypes[]
  readonly featureFlags: FeatureFlags[]
  readonly subscriptionState?: SubscriptionState
  readonly user: EmployeeInterface
  readonly loginUrl?: string
}
