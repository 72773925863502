import '@toast-ui/calendar/dist/toastui-calendar.min.css'

import React, { useEffect, useMemo } from 'react'

import { DateType } from '@components/LargeWeeklyCalendar/types'
import { Widget, Token } from '@revolut/ui-kit'
import { CandidateSchedulingInterview } from '@src/pages/Forms/Candidate/CandidateSchedulingInterview/CandidateSchedulingInterview'
import { useCandidateProfileContext } from '@src/pages/Forms/Candidate/CandidateProfileContext'
import { useLocation, useParams } from 'react-router-dom'
import {
  CandidateInterface,
  InterviewRoundInterface,
} from '@src/interfaces/interviewTool'
import { useGetCustomPotentialInterviewSlots } from '@src/api/recruitment/interviewerScheduling'
import compact from 'lodash/compact'
import { connect } from 'lape'
import { useScheduleInterviewContext } from '@src/pages/Forms/Candidate/ScheduleInterview/ScheduleInterviewContext'
import add from 'date-fns/add'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { LargeWeeklyCalendar } from '@components/LargeWeeklyCalendar'
import { utcToZonedTime } from 'date-fns-tz'

export const getFormattedTime = (date: Date) =>
  `${date.getHours().toString().padStart(2, '0')}:${date
    .getMinutes()
    .toString()
    .padStart(2, '0')}`

export const getDateFromEventDate = (eventDate?: DateType) => {
  if (!eventDate) {
    return null
  }

  if (typeof eventDate === 'string' || typeof eventDate === 'number') {
    return new Date(eventDate)
  }

  if ('toDate' in eventDate) {
    return eventDate.toDate()
  }

  return eventDate
}

type ScheduleInterviewRootProps = {
  candidate: CandidateInterface
}

const ScheduleInterviewRoot = ({ candidate }: ScheduleInterviewRootProps) => {
  const { candidateId } = useParams<{ candidateId: string }>()
  const {
    duration,
    durationUnit,
    interviewer,
    additionalInterviewers,
    interviewStage,
    setCalendarEvent,
    calendarEvent,
    timeZone,
    disabledCalendar,
  } = useScheduleInterviewContext()
  const { setActiveAction } = useCandidateProfileContext()
  const location = useLocation<{ round: InterviewRoundInterface }>()

  const { data: slots } = useGetCustomPotentialInterviewSlots(
    interviewStage?.id,
    location.state?.round?.id,
    compact([interviewer, ...(additionalInterviewers || [])]),
    true,
    duration !== undefined && duration < 10 ? 10 : duration,
    durationUnit?.id,
  )

  useEffect(() => {
    setActiveAction({
      type: 'schedule',
      mode: 'scheduling',
    })
  }, [])

  const calendarSlots = useMemo(() => {
    const calendarEventStartDate = getDateFromEventDate(calendarEvent?.start)
    const calendarEventEndDate = getDateFromEventDate(calendarEvent?.end)

    const s = []

    if (slots) {
      s.push(...slots)
    }

    if (calendarEvent && calendarEventStartDate && calendarEventEndDate) {
      if (
        // TODO: not optimal, https://revolut.atlassian.net/browse/PERF-5670
        !s.find(
          slot =>
            new Date(slot.slot_start).getTime() === calendarEventStartDate.getTime() &&
            new Date(slot.slot_end).getTime() === calendarEventEndDate.getTime(),
        )
      ) {
        s.push({
          slot_start: calendarEventStartDate.toISOString(),
          slot_end: calendarEventEndDate.toISOString(),
          free_interviewers: [],
        })
      }
    }
    return (
      s.map(slot => {
        const isSelected =
          calendarEventStartDate &&
          calendarEventStartDate.getTime() === new Date(slot.slot_start).getTime()

        return {
          start: timeZone?.id
            ? utcToZonedTime(slot.slot_start, timeZone?.id)
            : new Date(slot.slot_start),
          end: timeZone?.id
            ? utcToZonedTime(slot.slot_end, timeZone?.id)
            : new Date(slot.slot_end),
          title: isSelected
            ? interviewer
              ? interviewer.display_name
              : 'Select interviewer'
            : 'Free',
          backgroundColor: isSelected ? Token.color.accent : Token.color.green_10,
          color: isSelected ? Token.color.white : Token.color.black,
          isReadOnly: true,
        }
      }) || []
    )
  }, [slots, calendarEvent, interviewer, timeZone])

  const disabled = disabledCalendar || !interviewStage || !duration

  return (
    <>
      <CandidateSchedulingInterview
        candidate={candidate}
        refreshStats={() => {}}
        round={location.state?.round}
        alwaysOpen
        onSuccess={() => {
          navigateTo(pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id: candidateId }))
        }}
        type="calendar"
      />
      <Widget p="s-16" height="90vh" width="100%">
        <LargeWeeklyCalendar
          events={calendarSlots}
          onAddEvent={event => {
            if (!event.start || !duration || disabledCalendar) {
              return
            }

            setCalendarEvent({
              start: event.start,
              end: add(event.start, {
                minutes: duration,
              }),
            })
          }}
          onClickEvent={({ event }) => {
            if (disabled) {
              return
            }
            setCalendarEvent(event)
          }}
          onChangeEvent={() => {}}
        />
      </Widget>
    </>
  )
}

export const ManualScheduleInterview = connect((props: ScheduleInterviewRootProps) => (
  <ScheduleInterviewRoot {...props} />
))
