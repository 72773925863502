import { FiltersSidebarItemInterface } from '@src/components/FiltersSidebar/FiltersSidebarItem'
import { selectorKeys } from '@src/constants/api'
import { departmentNameRevolutersColumn } from '@src/constants/columns/department'
import {
  employeeCountryColumn,
  employeeEntityColumn,
  employeeNameColumn,
  employeeNameIdStatusColumn,
  employeeStartedAtColumn,
  employeeTypeColumn,
} from '@src/constants/columns/employee'
import { locationNameColumn } from '@src/constants/columns/location'
import { roleNameColumn } from '@src/constants/columns/role'
import { seniorityNameColumn } from '@src/constants/columns/seniority'
import { teamNameColumn } from '@src/constants/columns/team'
import { FilterType, RowInterface } from '@src/interfaces/data'
import { RevolutersInterface } from '@src/interfaces/revoluters'
import { OptionInterface } from '@src/interfaces/selectors'

export const Row: RowInterface<RevolutersInterface> = {
  cells: [
    {
      ...employeeNameColumn,
      width: 150,
    },
    {
      ...employeeTypeColumn,
      width: 100,
    },
    {
      ...departmentNameRevolutersColumn,
      width: 100,
    },
    {
      ...teamNameColumn,
      width: 100,
    },
    {
      ...roleNameColumn,
      width: 130,
    },
    {
      ...seniorityNameColumn,
      width: 100,
    },
    {
      ...employeeCountryColumn,
      width: 100,
    },
    {
      ...locationNameColumn,
      width: 100,
    },
    {
      ...employeeEntityColumn,
      width: 120,
    },
    {
      ...employeeStartedAtColumn,
      width: 120,
    },
    {
      ...employeeNameIdStatusColumn,
      width: 160,
    },
  ],
}

const canShowFilter = (
  col: {
    filterKey: string | null
    selectorsKey: selectorKeys | (() => Promise<{ options: OptionInterface[] }>)
  },
  filterType: FilterType,
) => {
  switch (filterType) {
    case FilterType.selector:
      return Boolean(col.filterKey && col.selectorsKey)
    case FilterType.date:
      return true
  }
  return false
}

export const filtersSidebarItems = [
  canShowFilter(employeeNameColumn, FilterType.selector) && {
    label: employeeNameColumn.title,
    field: employeeNameColumn.filterKey,
    filterType: FilterType.selector,
    selector: employeeNameColumn.selectorsKey,
    icon: 'Profile',
  },
  canShowFilter(employeeTypeColumn, FilterType.selector) && {
    label: 'Employee type',
    field: employeeTypeColumn.filterKey,
    filterType: FilterType.selector,
    selector: employeeTypeColumn.selectorsKey,
    icon: 'InfoOutline',
  },
  canShowFilter(departmentNameRevolutersColumn, FilterType.selector) && {
    label: departmentNameRevolutersColumn.title,
    field: departmentNameRevolutersColumn.filterKey,
    filterType: FilterType.selector,
    selector: departmentNameRevolutersColumn.selectorsKey,
    icon: 'Services',
  },
  canShowFilter(teamNameColumn, FilterType.selector) && {
    label: teamNameColumn.title,
    field: teamNameColumn.filterKey,
    filterType: FilterType.selector,
    selector: teamNameColumn.selectorsKey,
    icon: 'People',
  },
  canShowFilter(roleNameColumn, FilterType.selector) && {
    label: roleNameColumn.title,
    field: roleNameColumn.filterKey,
    filterType: FilterType.selector,
    selector: roleNameColumn.selectorsKey,
    icon: 'RepairTool',
  },
  canShowFilter(seniorityNameColumn, FilterType.selector) && {
    label: seniorityNameColumn.title,
    field: seniorityNameColumn.filterKey,
    filterType: FilterType.selector,
    selector: seniorityNameColumn.selectorsKey,
    icon: 'StarSemi',
  },
  canShowFilter(employeeCountryColumn, FilterType.selector) && {
    label: employeeCountryColumn.title,
    field: employeeCountryColumn.filterKey,
    filterType: FilterType.selector,
    selector: employeeCountryColumn.selectorsKey,
    icon: 'LocationPin',
  },
  canShowFilter(locationNameColumn, FilterType.selector) && {
    label: locationNameColumn.title,
    field: locationNameColumn.filterKey,
    filterType: FilterType.selector,
    selector: locationNameColumn.selectorsKey,
    icon: 'MapPoint',
  },
  canShowFilter(employeeEntityColumn, FilterType.selector) && {
    label: employeeEntityColumn.title,
    field: employeeEntityColumn.filterKey,
    filterType: FilterType.selector,
    selector: employeeEntityColumn.selectorsKey,
    icon: 'Bank',
  },
  canShowFilter(employeeStartedAtColumn, FilterType.date) && {
    label: employeeStartedAtColumn.title,
    field: employeeStartedAtColumn.filterKey,
    filterType: FilterType.date,
    icon: 'CalendarDate',
  },
  canShowFilter(employeeNameIdStatusColumn, FilterType.selector) && {
    label: employeeNameIdStatusColumn.title,
    field: employeeNameIdStatusColumn.filterKey,
    filterType: FilterType.selector,
    selector: employeeNameIdStatusColumn.selectorsKey,
    icon: 'CheckVerification',
  },
].filter(Boolean) as FiltersSidebarItemInterface[]
