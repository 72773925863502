import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  ActionButton,
  ActionButtonSkeleton,
  ProgressStep,
  ProgressSteps,
} from '@revolut/ui-kit'
import { useGetPerformanceCycleTimelineEvents } from '@src/api/performance'
import {
  PerformanceSelector,
  SingleTimelineEventInterface,
} from '@src/interfaces/performance'
import { canAddTeamKpi, selectFeatureFlags, selectUser } from '@src/store/auth/selectors'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  getTimelineStepDescription,
  getTimelineStepState,
} from '@src/pages/EmployeeProfile/Layout/Performance/utils'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useCanViewMeetingsTab } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { NominateForPromotion } from '@src/pages/EmployeeProfile/Preview/components/Buttons/Actions/NominateForPromotion'
import { ViewNominationButton } from '@src/pages/EmployeeProfile/Preview/PerformanceSummary/PerformanceTimeline/components/ViewNominationButton'
import { StepReviewersList } from '@src/pages/EmployeeProfile/Preview/Performance/ProbationTimeline/StepReviewersList'
import { Statuses } from '@src/interfaces'
import { SectionLoader } from '@src/pages/EmployeeProfile/Layout/Performance/SectionLoader'
import { useHasNewScorecards } from '@src/utils/performance'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { captureException } from '@sentry/react'
import { createDraftGoal } from '@src/api/goals'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { useGetSelectors } from '@src/api/selectors'
import { GoalContentType } from '@src/interfaces/goals'
import { selectorKeys } from '@src/constants/api'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { getKPIFormInitialValues } from '@src/pages/Forms/KpiForm/General'

enum ButtonStates {
  Add = 'Add',
  Edit = 'Edit',
  Continue = 'Continue',
}

const ButtonTitles = {
  [ButtonStates.Add]: 'Add Review',
  [ButtonStates.Edit]: 'Edit Review',
  [ButtonStates.Continue]: 'Continue Review',
}

const ButtonIcon = {
  [ButtonStates.Add]: 'Plus' as const,
  [ButtonStates.Edit]: 'Pencil' as const,
  [ButtonStates.Continue]: 'Pencil' as const,
}

interface Props {
  data: EmployeeInterface
  selectedPeriod: PerformanceSelector
  performanceLink?: string
}

export const PerformanceTimeline = ({ data, selectedPeriod, performanceLink }: Props) => {
  const [isCreateGoalPending, setIsCreateGoalPending] = useState(false)
  const { navigateWithEntity, entity } = useOrgEntity()
  const { data: contentTypes, isLoading: contentTypesLoading } =
    useGetSelectors<GoalContentType>(selectorKeys.goal_content_types)
  const showStatusPopup = useShowStatusPopup()
  const canAddKpi = useSelector(canAddTeamKpi)
  const canViewMeetings = useCanViewMeetingsTab(data)
  const user = useSelector(selectUser)
  const showNewScorecards = useHasNewScorecards()
  const featureFlags = useSelector(selectFeatureFlags)
  const goalsEnabled = featureFlags.includes(FeatureFlags.CanAddGoals)
  const canAddGoals = !!entity?.data.field_options?.permissions?.includes(
    PermissionTypes.CanAddGoals,
  )

  const { data: timelineEvents, isLoading } = useGetPerformanceCycleTimelineEvents(
    data.id,
    selectedPeriod.id,
  )

  if (isLoading) {
    return <SectionLoader />
  }

  if (!timelineEvents) {
    return null
  }

  const onAddGoalsClick = async () => {
    if (goalsEnabled) {
      try {
        setIsCreateGoalPending(true)
        const response = await createDraftGoal({
          owner: { id: user.id },
          content_type: contentTypes?.find(({ model }) => model === entity?.type),
          object_id: entity?.data.id,
        })
        navigateWithEntity(pathToUrl(ROUTES.FORMS.GOAL.EDIT, { id: response.data.id }), {
          reviewCycleId: selectedPeriod.id,
          isNew: true,
        })
      } catch (err) {
        captureException(err)

        showStatusPopup({
          status: 'error',
          title: 'Failed to create goal',
          description: getStringMessageFromError(
            err,
            'Something went wrong. Please try again.',
          ),
        })
      } finally {
        setIsCreateGoalPending(false)
      }
    } else {
      navigateTo(pathToUrl(ROUTES.FORMS.KPI.GENERAL), {
        initialValues: getKPIFormInitialValues(
          {
            id: data.id,
            name: data.full_name,
            team: {
              // @ts-ignore FIXME: REVPI-19 support optional team value
              id: data.team.id,
              // @ts-ignore FIXME: REVPI-19 support optional team value
              name: data.team.name,
            },
          },
          { is_employee: true },
        ),
      })
    }
  }

  const renderButton = (event: SingleTimelineEventInterface) => {
    if (event.category === 'kpi' && (goalsEnabled ? canAddGoals : canAddKpi)) {
      return (
        <ProgressStep.Side>
          {contentTypesLoading ? (
            <ActionButtonSkeleton />
          ) : (
            <ActionButton
              useIcon="Plus"
              onClick={onAddGoalsClick}
              pending={isCreateGoalPending}
            >
              Add goals
            </ActionButton>
          )}
        </ProgressStep.Side>
      )
    }

    if (event.category === 'meeting') {
      return (
        <ProgressStep.Side>
          <ActionButton
            useIcon="Plus"
            onClick={() => {
              navigateTo(
                pathToUrl(ROUTES.FORMS.EMPLOYEE.FEEDBACK.ONE_TO_ONE, {
                  userId: data.id,
                }),
              )
            }}
          >
            Add note
          </ActionButton>
        </ProgressStep.Side>
      )
    }

    if (event.category === 'promotion') {
      return (
        <ProgressStep.Side>
          <NominateForPromotion data={data} cycleId={selectedPeriod.id} />
          <ViewNominationButton data={data} cycleId={selectedPeriod.id} />
        </ProgressStep.Side>
      )
    }

    return null
  }

  const renderPerformanceReviewers = (
    event: SingleTimelineEventInterface,
    eventState: string,
  ) => {
    if (!event.reviews?.length) {
      return null
    }

    const currentUserReview = event.reviews.find(review => review.reviewer.id === user.id)
    const buttonDisabled = currentUserReview?.can_submit
      ? false
      : eventState !== 'pending' || !currentUserReview

    let state = ButtonStates.Add
    if (currentUserReview?.status === Statuses.completed) {
      state = ButtonStates.Edit
    }
    if (currentUserReview?.status === Statuses.draft) {
      state = ButtonStates.Continue
    }

    const reviewLink = showNewScorecards
      ? ROUTES.FORMS.EMPLOYEE_PERFORMANCE_LAYOUT
      : ROUTES.FORMS.EMPLOYEE_PERFORMANCE.GENERAL

    return (
      <ProgressStep.Description>
        <StepReviewersList
          reviews={event.reviews}
          onClickAddReview={e => {
            e.stopPropagation()
            navigateTo(
              pathToUrl(reviewLink, {
                id: currentUserReview?.id,
                employeeId: data.id,
              }),
            )
          }}
          buttonDisabled={buttonDisabled}
          icon={ButtonIcon[state]}
          title={ButtonTitles[state]}
          performanceLink={performanceLink}
        />
      </ProgressStep.Description>
    )
  }

  return (
    <>
      <ProgressSteps variant="vertical-compact">
        {timelineEvents.length > 0 &&
          timelineEvents.map((event, i) => {
            if (event.category === 'meeting' && !canViewMeetings) {
              return null
            }

            const state = getTimelineStepState(
              event.start_period,
              event.end_period,
              event.category,
            )
            const description = getTimelineStepDescription(event)

            return (
              <ProgressStep
                data-testid={`${event.title}--${state}`}
                key={i}
                state={state}
              >
                <ProgressStep.Title>{event.title}</ProgressStep.Title>
                <ProgressStep.Description>{description}</ProgressStep.Description>
                {event.category === 'review' && renderPerformanceReviewers(event, state)}
                {renderButton(event)}
              </ProgressStep>
            )
          })}
      </ProgressSteps>
    </>
  )
}
